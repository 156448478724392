import { GlobalStyles } from "@mui/system";

const defaultStyles = {
  html: {
    WebkitFontSmoothing: "antialiased", // Antialiasing.
    MozOsxFontSmoothing: "grayscale", // Antialiasing.
    // Change from `box-sizing: content-box` so that `width`
    // is not affected by `padding` or `border`.
    boxSizing: "border-box",
    // Fix font resize problem in iOS
    WebkitTextSizeAdjust: "100%",
  },
  "*, *::before, *::after": {
    boxSizing: "inherit",
  },
  "strong, b": {
    fontWeight: "bold",
  },
  body: {
    margin: 0, // Remove the margin in all browsers.
    color: "#ffffff",
    backgroundColor: "#000000",
    // Add support for document.body.requestFullScreen().
    // Other elements, if background transparent, are not supported.
    "&::backdrop": {
      backgroundColor: "#000000",
    },
  },
} as const;

function CssBaseline() {
  return <GlobalStyles styles={defaultStyles} />;
}

export default CssBaseline;
