import {
  QueryFunctionContext,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";

import { getCurrentInstitutionalPlaylist as _getCurrentInstitutionalPlaylist } from "api";
import { sliceQueryFunctionContext } from "utils";

const getCurrentInstitutionalPlaylist = sliceQueryFunctionContext(
  (context: QueryFunctionContext<string[]>) => {
    const { queryKey } = context;
    const args: [string] = [queryKey[1]];
    return args;
  },
  _getCurrentInstitutionalPlaylist
);

export function useInstitutionalPlaylist(
  siteId: string,
  options?: UseQueryOptions<string[], unknown, string[], string[]>
) {
  const queryKey = ["institutional-playlist", siteId];

  return useQuery({
    queryKey,
    queryFn: getCurrentInstitutionalPlaylist,
    ...options,
  });
}
