import { defaultFetchOptions } from "utils";
import { SiteSchema } from "../core";

export async function getSite(id: string) {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_PATH}/ledwall/${id}`,
    defaultFetchOptions
  );

  return SiteSchema.parse(await response.json());
}
