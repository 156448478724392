import { useQuery } from "@tanstack/react-query";
import banner from "../assets/banner.jpeg";

async function exists(url: string) {
  const response = await fetch(url, {
    method: "GET",
  });

  return response.status === 200;
}

const getDefaultInstitutional = (siteId: string) =>
  `${process.env.REACT_APP_BASE_API_PATH}/image/video/sites/${siteId}/default-institutional`;

const getDefaultCampaign = (siteId: string) =>
  `${process.env.REACT_APP_BASE_API_PATH}/image/video/sites/${siteId}/default-campaign`;

const getDefaultFooter = (siteId: string) =>
  `${process.env.REACT_APP_BASE_API_PATH}/image/video/sites/${siteId}/default-footer`;

export function useDefaultContents(siteId: string) {
  const { data: institutional } = useQuery(
    ["default-institutional"],
    () => exists(getDefaultInstitutional(siteId)),
    {
      retry: false,
    }
  );

  const { data: campaign } = useQuery(
    ["default-campaign"],
    () => exists(getDefaultCampaign(siteId)),
    {
      retry: false,
    }
  );

  const { data: footer } = useQuery(
    ["default-footer"],
    () => exists(getDefaultFooter(siteId)),
    {
      retry: false,
    }
  );

  return {
    institutional: institutional ? getDefaultInstitutional(siteId) : undefined,
    campaign: campaign
      ? getDefaultCampaign(siteId)
      : undefined,
    footer: footer ? getDefaultFooter(siteId) : banner,
  };
}
