import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeSentry } from "infrastructure/sentryConfiguration";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_MOCK === "true"
) {
  require("./be-mock");
}

if (
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_ENABLE_SENTRY === "true"
) {
  initializeSentry();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
