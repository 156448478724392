import {
  useQuery,
  QueryFunctionContext,
  UseQueryOptions,
} from "@tanstack/react-query";

import { getCurrentCommercialPlaylist as _getCurrentCommercialPlaylist } from "api";
import { sliceQueryFunctionContext } from "utils";

const getCurrentCommercialPlaylist = sliceQueryFunctionContext(
  (context: QueryFunctionContext<string[]>) => {
    const { queryKey } = context;
    const args: [string] = [queryKey[1]];
    return args;
  },
  _getCurrentCommercialPlaylist
);

export function useCommercialPlaylist(
  siteId: string,
  options?: UseQueryOptions<
    {
      morning: string[];
      afternoon: string[];
      evening: string[];
    },
    unknown,
    {
      morning: string[];
      afternoon: string[];
      evening: string[];
    },
    string[]
  >
) {
  const queryKey = ["commercial-playlist", siteId];

  return useQuery({
    queryKey,
    queryFn: getCurrentCommercialPlaylist,
    ...options,
  });
}
