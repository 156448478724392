import { getDefaultPlaylistContent } from "utils";
import { z } from "zod";

export const CommercialPlaylistSchema = z.object({
  morning: z.array(z.string()),
  afternoon: z.array(z.string()),
  evening: z.array(z.string()),
});

export type CommercialPlaylist = z.TypeOf<typeof CommercialPlaylistSchema>;

export const InstitutionalPlaylistSchema = z.array(z.string());

export type InstitutionalPlaylist = z.TypeOf<
  typeof InstitutionalPlaylistSchema
>;

export const PlaylistSchema = z.object({
  morning: z.array(z.string()),
  afternoon: z.array(z.string()),
  evening: z.array(z.string()),
});

export type Playlist = z.TypeOf<typeof PlaylistSchema>;

export function mergePlaylists(
  commercial: CommercialPlaylist,
  institutional: InstitutionalPlaylist,
  defaultCommercial?: string,
  defaultInstitutional?: string
): Playlist {
  const _institutional =
    defaultInstitutional && institutional.length === 0
      ? [defaultInstitutional]
      : institutional;
  const morning = concat(
    _institutional,
    fillPlaylistSlot(commercial.morning, defaultCommercial)
  );
  const evening = concat(
    _institutional,
    fillPlaylistSlot(commercial.evening, defaultCommercial)
  );
  const afternoon = concat(
    _institutional,
    fillPlaylistSlot(commercial.afternoon, defaultCommercial)
  );

  return {
    morning,
    evening,
    afternoon,
  };
}

function concat(institutional: InstitutionalPlaylist, slot: string[]) {
  if (institutional.length === 0) {
    if (slot.length === 0) {
      return [getDefaultPlaylistContent()]
    }
    return slot;
  }

  return institutional.flatMap((el) => [...slot, el]);
}

function fillPlaylistSlot(slot: string[], defaultCommercial?: string) {
  if (defaultCommercial) {
    switch (slot.length) {
      case 0: {
        return [defaultCommercial];
      }
      case 1:
      case 2:
      case 4: {
        return [...slot, defaultCommercial];
      }
      case 3: {
        return [slot[0], defaultCommercial, slot[1], defaultCommercial, slot[2]];
      }
      default: {
        return slot;
      }
    }
  } else {
    return slot
  }
}
