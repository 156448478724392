import { Suspense, useEffect } from "react";
import { styled } from "@mui/system";
import {
  RouterProvider,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { CssBaseline } from "components";
import { ScreenSaver, Slider } from "pages";
import { sentryCreateBrowserRouter } from "infrastructure/sentryConfiguration";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: true
    },
  },
});

const Fullscreen = styled("div")({
  width: "100vw",
  height: "100vh",
});

function Root() {
  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
}

function MidnightRedirectSlider() {
  const navigate = useNavigate();

  useEffect(() => {
    const now = new Date();
    const then = new Date(now);
    then.setHours(24, 0, 0, 0);

    const id = setTimeout(() => {
      navigate("screen-saver");
    }, then.getTime() - now.getTime());

    return () => {
      clearTimeout(id);
    };
  });

  return <Slider />;
}

const router = sentryCreateBrowserRouter([
  {
    path: "/:screenId",
    element: <Root />,
    children: [
      {
        index: true,
        element: <MidnightRedirectSlider />,
      },
      {
        path: "screen-saver",
        element: <ScreenSaver />,
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Fullscreen>
        <CssBaseline />
        <RouterProvider router={router} />
      </Fullscreen>
    </QueryClientProvider>
  );
}

export default App;
