import {
  useQuery,
  QueryFunctionContext,
  UseQueryOptions,
} from "@tanstack/react-query";

import { getEmergenciesNews as _getEmergenciesNews } from "api";
import { sliceQueryFunctionContext } from "utils";

const getEmergenciesNews = sliceQueryFunctionContext(
  (context: QueryFunctionContext<string[]>) => {
    const { queryKey } = context;
    const args: [string] = [queryKey[1]];
    return args;
  },
  _getEmergenciesNews
);

export function useEmergencies(
  siteId: string,
  options?: UseQueryOptions<string[], unknown, string[], string[]>
) {
  const queryKey = ["emergencies", siteId];

  return useQuery({
    queryKey,
    queryFn: getEmergenciesNews,
    ...options,
  });
}
