import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export type SliderParams = {
  screenId: string;
};

function ScreenSaverPage() {
  const params = useParams<SliderParams>();
  const navigate = useNavigate();

  useEffect(() => {
    const now = new Date();
    const then = new Date(now);
    then.setHours(6, 0, 0, 0);

    const id = setTimeout(() => {
      navigate(`/${params.screenId}`);
    }, then.getTime() - now.getTime());

    return () => {
      clearTimeout(id);
    };
  });

  return null;
}

export default ScreenSaverPage;
