import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { getSite as _getSite } from "api";
import { sliceQueryFunctionContext } from "utils";

const getSite = sliceQueryFunctionContext(
  (context: QueryFunctionContext<string[]>) => {
    const { queryKey } = context;
    const args: [string] = [queryKey[1]];
    return args;
  },
  _getSite
);

export function useSite(siteId: string) {
  const queryKey = ["site", siteId];

  return useQuery({
    queryKey,
    queryFn: getSite,
  });
}
