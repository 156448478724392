import { z } from "zod";

import { Weather } from "../../core";

const CurrentWeatherResponseSchema = z
  .object({
    weather: z
      .array(
        z.object({
          main: z.string(),
          description: z.string(),
          icon: z.string(),
        })
      )
      .min(1),
    main: z.object({
      temp: z.number(),
    }),
  })
  .transform(
    (val) =>
      ({
        icon: val.weather[0].icon,
        temperature: val.main.temp,
      } satisfies Weather)
  );

export type CurrentWeatherResponse = z.TypeOf<
  typeof CurrentWeatherResponseSchema
>;

export async function currentWeather(
  lat: string,
  lon: string
): Promise<CurrentWeatherResponse | undefined> {
  const response = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${process.env.REACT_APP_OPEN_WEATHER_KEY}&lang=it&units=metric`,
    {
      credentials: "omit",
    }
  );

  return CurrentWeatherResponseSchema.parse(await response.json());
}
