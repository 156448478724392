import { styled } from "@mui/system";
import { CSSProperties, memo } from "react";
import { useParams } from "react-router-dom";

import { Clock, Newscast } from "../../components";
import { Site } from "../../core";
import { useCurrentWeather, useNews, useSite } from "../../hooks";
import { useEmergencies } from "hooks/useEmergencies";

type FooterContainerProps = {
  height: CSSProperties["height"];
};

const FooterContainer = styled("div")<FooterContainerProps>(({ height }) => ({
  height: height,
  maxHeight: height,
  display: "flex",
  fontSize: "4.1vh",
}));

const WidgetContainer = styled("div")({
  width: "21vw",
  maxWidth: "21vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  background: "white",
  color: "black",
});

const NewscastContainer = styled("div")({
  maxWidth: "calc(100vw - 12vw)",
  width: "calc(100vw - 21vw)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const BannerImg = styled("img")({
  width: "100%",
  maxWidth: "100%",
});

export type SliderParams = {
  screenId: string;
};

type FooterProps = {
  banner: string;
  emergency?: boolean;
} & FooterContainerProps;

export const Footer = memo(function Footer({ height, banner, emergency }: FooterProps) {
  const params = useParams<SliderParams>();
  const { data: site } = useSite(params.screenId!);

    return (
      <FooterContainer height={height}>
        <NewscastContainer>
          {site && (
            <NewscastStatefulContainer site={site} emergency={emergency} />
          )}
          <BannerImg src={banner} alt="banner" />
        </NewscastContainer>
        <WidgetContainer>
          <Clock />
          {site && <Weather site={site} />}
        </WidgetContainer>
      </FooterContainer>
    );
  },
  (prev, next) => prev.emergency !== next.emergency
);

const StyledNewscast = styled(Newscast)({
  flex: 1,
  display: "flex",
  alignItems: "center",
});

type Props = {
  site: Site;
  emergency?: boolean;
};

function NewscastStatefulContainer({ site, emergency }: Props) {
  const params = useParams<SliderParams>();
  const { data } = useNews(params.screenId!, {
    refetchInterval: Number(process.env.REACT_APP_NEWS_INTERVAL),
  });
  const { data: emergencies } = useEmergencies(params.screenId!, {
    enabled: emergency,
    refetchInterval: Number(process.env.REACT_APP_EMERGENCY_INTERVAL),
  });
  const news = data && data.length ? data : [`Benvenuti a ${site?.city_name.replace(/ - TOTEM$| - LEDWALL$/i, '')}`];
  const newsOrEmergencies = emergencies && emergencies.length ? emergencies : news

  return <StyledNewscast news={newsOrEmergencies} />;
}

const WeatherIcon = styled("img")({
  height: "1.5em",
});

function Weather({ site }: Props) {
  const { data } = useCurrentWeather(site.latitude, site.longitude, {
    refetchInterval: Number(process.env.REACT_APP_WEATHER_INTERVAL),
  });

  if (!data) {
    return null;
  }

  const temp = data.temperature.toFixed(0);

  return (
    <div
      style={{
        fontSize: "1.25em",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <WeatherIcon src={`http://openweathermap.org/img/wn/${data.icon}.png`} />
      <span>{temp}° C</span>
    </div>
  );
}
