import { QueryFunctionContext } from "@tanstack/react-query";

export function sliceQueryFunctionContext<
  QFC extends QueryFunctionContext = QueryFunctionContext,
  Args extends unknown[] = unknown[],
  Return extends unknown = unknown
>(
  mapQueryFunctionContextToProps: (context: QFC) => Args,
  asyncFn: (...args: Args) => Promise<Return>
) {
  return (context: QFC) => asyncFn(...mapQueryFunctionContextToProps(context));
}
