import { styled, keyframes } from "@mui/system";

const Root = styled("div")({
  overflow: "hidden",
});

const tickerh = keyframes`
    0% { transform: translateX(404px); }
    100% { transform: translateX(-100%); }
`;

const Container = styled("div")<{ charsPerSecond: number; charsCount: number }>(
  ({ charsPerSecond, charsCount }) => ({
    display: "flex",
    animation: `${tickerh} linear ${Math.round(
      charsCount * (1 / charsPerSecond)
    )}s infinite`,
    width: "fit-content",
  })
);

const Item = styled("div")({
  flexShrink: 0,
  boxSizing: "border-box",
  padding: 10,
  textAlign: "center",
  fontSize: "2em",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  color: "#f2c428",
  fontWeight: "bold",
});

export type NewscastProps = {
  news: string[];
  charsPerSecond?: number;
  className?: string;
};

function Newscast({ news, charsPerSecond = 3.5, className }: NewscastProps) {
  const items = news.flatMap((el, i) =>
    i !== news.length - 1
      ? [<Item key={i}>{el}</Item>, <Item key={`${i}-`}>-</Item>]
      : [<Item key={i}>{el}</Item>]
  );
  const charsCount =
    news.reduce((acc, news) => acc + news.length, 0) + news.length;

  return (
    <Root className={className}>
      <Container charsPerSecond={charsPerSecond} charsCount={charsCount}>
        {items}
      </Container>
    </Root>
  );
}

export default Newscast;
