import { useState, useCallback, useRef, useEffect, useMemo } from "react";

import {
  CommercialPlaylist,
  InstitutionalPlaylist,
  mergePlaylists,
} from "../core";

export function usePlaylists(
  commercial: CommercialPlaylist,
  institutional: InstitutionalPlaylist,
  defaultCommercial?: string,
  defaultInstitutional?: string
) {
  const [currentSlot, setCurrentSlot] = useState<string[]>([]);
  const playlistMemo = useMemo(
    () =>
      mergePlaylists(
        commercial,
        institutional,
        defaultCommercial,
        defaultInstitutional
      ),
    [commercial, defaultCommercial, defaultInstitutional, institutional]
  );
  const playlistRef = useRef(playlistMemo);
  const timeoutIdRef = useRef<string | number | NodeJS.Timeout>();

  const changeCurrentSlot = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    const now = new Date();
    const then = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );
    const hour = now.getHours();
    const playlist = playlistRef.current;

    if (6 <= hour && hour < 12) {
      setCurrentSlot(playlist.morning);
      then.setHours(12, 0, 0, 0);
    } else if (12 <= hour && hour < 18) {
      setCurrentSlot(playlist.afternoon);
      then.setHours(18, 0, 0, 0);
    } else if (hour >= 18) {
      setCurrentSlot(playlist.evening);
      then.setHours(24, 0, 0, 0);
    } else {
      setCurrentSlot([]);
      then.setHours(6, 0, 0, 0);
    }

    timeoutIdRef.current = setTimeout(() => {
      changeCurrentSlot();
    }, then.getTime() - now.getTime());
  }, []);

  useEffect(() => {
    playlistRef.current = playlistMemo;
    changeCurrentSlot();
  }, [changeCurrentSlot, playlistMemo]);

  return currentSlot;
}
