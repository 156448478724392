import {
  useQuery,
  QueryFunctionContext,
  UseQueryOptions,
} from "@tanstack/react-query";

import { currentWeather as _currentWeather, CurrentWeatherResponse } from "api";
import { sliceQueryFunctionContext } from "utils";

const currentWeather = sliceQueryFunctionContext(
  (context: QueryFunctionContext<[string, [string, string]]>) => {
    const { queryKey } = context;
    const args = queryKey[1];
    return args;
  },
  _currentWeather
);

export function useCurrentWeather(
  lat: string,
  lon: string,
  options?: UseQueryOptions<
    CurrentWeatherResponse | undefined,
    unknown,
    CurrentWeatherResponse | undefined,
    [string, [string, string]]
  >
) {
  const queryKey: [string, [string, string]] = ["current-weather", [lat, lon]];

  return useQuery({
    queryKey,
    queryFn: currentWeather,
    ...options,
  });
}
