import { styled } from "@mui/system";
import { CSSProperties, memo } from "react";
import { useParams } from "react-router-dom";

import { Clock, Newscast } from "../../components";
import { Site } from "../../core";
import { useCurrentWeather, useNews, useSite } from "../../hooks";
import { useEmergencies } from "hooks/useEmergencies";

type FooterVerticalContainerProps = {
  height: CSSProperties["height"];
};

const FooterVerticalContainer = styled("div")<FooterVerticalContainerProps>(({ height }) => ({
  height: height,
  maxHeight: height,
  display: "flex",
  flexDirection: "column",
  fontSize: "3.2vh",
}));

const WidgetVerticalContainer = styled("div")({
  height: "30%",
  padding: "0 24px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  background: "white",
  color: "black",
});

const WeatherDateContainer = styled("div")({
  width: "70%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  background: "white",
  color: "black",
});

const BannerVerticalImg = styled("img")({
  height: "30%",
  width: "100%",
  maxWidth: "100%",
});

export type SliderVerticalParams = {
  screenId: string;
};

type FooterVerticalProps = {
  banner: string;
  emergency?: boolean;
} & FooterVerticalContainerProps;

export const FooterVertical = memo(function Footer({ height, banner, emergency }: FooterVerticalProps) {
  const params = useParams<SliderVerticalParams>();
  const { data: site } = useSite(params.screenId!);
  const date = new Date().toLocaleDateString("it-IT", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  return (
    <FooterVerticalContainer height={height}>
      {site && (
        <NewscastStatefulContainer site={site} emergency={emergency} />
      )}
      <WidgetVerticalContainer>
        <WeatherDateContainer >
          <DateContainer>{date}</DateContainer>
          {site && <Weather site={site} hasDate={true} />}
        </WeatherDateContainer>
        <Clock hasDate={false} />
      </WidgetVerticalContainer>
      <BannerVerticalImg src={banner} alt="banner" />
    </FooterVerticalContainer>
  );
},
  (prev, next) => prev.emergency !== next.emergency
);

const StyledNewscast = styled(Newscast)({
  flex: 1,
  display: "flex",
  alignItems: "center",
});

type Props = {
  site: Site;
  emergency?: boolean;
  hasDate?: boolean;
};

function NewscastStatefulContainer({ site, emergency }: Props) {
  const params = useParams<SliderVerticalParams>();
  const { data } = useNews(params.screenId!, {
    refetchInterval: Number(process.env.REACT_APP_NEWS_INTERVAL),
  });
  const { data: emergencies } = useEmergencies(params.screenId!, {
    enabled: emergency,
    refetchInterval: Number(process.env.REACT_APP_EMERGENCY_INTERVAL),
  });
  const news = data && data.length ? data : [`Benvenuti a ${site?.city_name.replace(/ - TOTEM$| - LEDWALL$/i, '')}`];
  const newsOrEmergencies = emergencies && emergencies.length ? emergencies : news

  return <StyledNewscast news={newsOrEmergencies} />;
}

const WeatherIcon = styled("img")({
  height: "1.5em",
});

const DateContainer = styled("div")({
  fontSize: "1em",
  textAlign: "center",
});

function Weather({ site, hasDate = false }: Props) {
  const { data } = useCurrentWeather(site.latitude, site.longitude, {
    refetchInterval: Number(process.env.REACT_APP_WEATHER_INTERVAL),
  });

  if (!data) {
    return null;
  }

  const temp = data.temperature.toFixed(0);

  return (
    <div
      style={{
        fontSize: "1.25em",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: hasDate ? "0px" : "0px",
      }}
    >
      <WeatherIcon src={`http://openweathermap.org/img/wn/${data.icon}.png`} />
      <span>{temp}° C</span>

    </div>
  );
}
