import {
  CommercialPlaylist,
  CommercialPlaylistSchema,
  InstitutionalPlaylistSchema,
} from "core";
import { defaultFetchOptions } from "utils";

export async function getCurrentCommercialPlaylist(siteId: string) {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_PATH}/ledwall/${siteId}/playlist/campaigns`,
    {
      method: "POST",
      ...defaultFetchOptions,
    }
  );

  if (!response.ok) {
    return {
      afternoon: [],
      evening: [],
      morning: [],
    } satisfies CommercialPlaylist;
  }

  return CommercialPlaylistSchema.parse(await response.json());
}

export async function getCurrentInstitutionalPlaylist(siteId: string) {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_PATH}/ledwall/${siteId}/playlist/institutionals`,
    {
      method: "POST",
      ...defaultFetchOptions,
    }
  );
  
  return InstitutionalPlaylistSchema.parse(await response.json());
}
