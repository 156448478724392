import { styled } from "@mui/system";
import { useEffect, useState } from "react";

const TimeContainer = styled("div")({
  fontSize: "2em",
  fontWeight: "bold",
  textAlign: "center",
});

const DateContainer = styled("div")({
  fontSize: "1em",
  textAlign: "center",
});

function getCurrentLocaleTime() {
  return new Date()
    .toLocaleTimeString("it-IT")
    .split(":")
    .filter((_, i) => i < 2)
    .join(":");
}

function Clock({hasDate = true}) {
  const [time, setTime] = useState(getCurrentLocaleTime());
  const date = new Date().toLocaleDateString("it-IT", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    const id = setInterval(() => {
      setTime(getCurrentLocaleTime());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div>
      <TimeContainer>{time}</TimeContainer>
      {hasDate && <DateContainer>{date}</DateContainer>}
    </div>
  );
}

export default Clock;
