import { z } from "zod";

const NewsSchema = z.array(z.string());

export async function getNews(siteId: string) {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_PATH}/ledwall/${siteId}/playlist/news`,
    {
      method: "POST",
    }
  );

  return NewsSchema.parse(await response.json());
}

export async function getEmergenciesNews(siteId: string) {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_PATH}/ledwall/${siteId}/playlist/news?emergency=true`,
    {
      method: "POST",
    }
  );

  return NewsSchema.parse(await response.json());
}
