import { useParams } from "react-router-dom";

import {
  useCommercialPlaylist,
  useDefaultContents,
  useInstitutionalPlaylist,
  usePlaylists,
} from "hooks";
import { AutoplaySlider } from "components";
import { Footer } from "./Footer";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEmergencies } from "hooks/useEmergencies";
import { FooterVertical } from "./FooterVertical";

export type SliderParams = {
  screenId: string;
};

type EmergencyProps = {
  emergency?: boolean;
  isVertical?: boolean;
};

const redFlash = keyframes`
0% { background: red; color: white }
49% { background: red; color: white }
50% { background: white; color: red }
100% { background: white; color: red }
`;

const Root = styled("div")<EmergencyProps>(({ emergency }) => ({
  ...(emergency && { animation: `${redFlash} linear 1.5s infinite` }),
}));

const paddingX = "24px";
const normalHeightExpression = "100vw / (16 / 9)";
const emergencyHeightExpression = `(100vw - ${paddingX}) / (16 / 9)`;
const paddingYExpression = `((${normalHeightExpression}) - (${emergencyHeightExpression})) / 2`;
const footerHeight = "calc(100vh - (100vw / (16 / 9)))";

const normalVerticalHeightExpression = "100vw / (3 / 4)";
const emergencyVerticalHeightExpression = `(100vw - ${paddingX}) / (3 / 4)`;
const paddingYExpressionVertical = `((${normalVerticalHeightExpression}) - (${emergencyVerticalHeightExpression})) / 2`;
const footerVerticalHeight = "calc(100vh - (100vw / (3 / 4)))";

const Layout = styled("div")<EmergencyProps>(({ emergency, isVertical }) => ({
  ...(emergency && { padding: `calc(${isVertical ? paddingYExpressionVertical : paddingYExpression}) ${paddingX}` }),
}));

function Slider() {
  const params = useParams<SliderParams>();
  const { data: commercialPlaylist } = useCommercialPlaylist(params.screenId!, {
    refetchInterval: Number(process.env.REACT_APP_COMMERCIAL_PLAYLIST_INTERVAL),
  });
  const { data: institutionalPlaylist } = useInstitutionalPlaylist(
    params.screenId!,
    {
      refetchInterval: Number(
        process.env.REACT_APP_INSTITUTIONAL_PLAYLIST_INTERVAL
      ),
    }
  );
  const { campaign, institutional, footer } = useDefaultContents(params.screenId!);

  const currentSlot = usePlaylists(
    commercialPlaylist || {
      afternoon: [],
      evening: [],
      morning: [],
    },
    institutionalPlaylist || [],
    campaign,
    institutional
  );

  const isEmergency = useIsEmergency(params.screenId!);


  let sliderHeight = isEmergency
    ? `calc(${emergencyHeightExpression})`
    : `calc(${normalHeightExpression})`;


    let isVertical = params.screenId !== undefined ? isScreenVertical(params.screenId) : false;
    if (isVertical) {
    sliderHeight = isEmergency
      ? `calc(${emergencyVerticalHeightExpression})`
      : `calc(${normalVerticalHeightExpression})`;
  }



  return (
    <Root emergency={isEmergency}>
      <Layout emergency={isEmergency} isVertical={isVertical}>
        <AutoplaySlider imgs={currentSlot} maxHeight={sliderHeight} />
      </Layout>

      {isVertical && <FooterVertical height={footerVerticalHeight} banner={footer} />}
      {!isVertical && <Footer height={footerHeight} banner={footer} />}

    </Root>
  );
}

function useIsEmergency(screenId: string) {
  const { data } = useEmergencies(screenId, {
    refetchInterval: Number(process.env.REACT_APP_EMERGENCY_INTERVAL),
  });

  return Boolean(data && data?.length);
}


function isScreenVertical(screenId: string) {
  const validScreenIds = ['384f08e2-817c-47b8-b371-111d046ae8e0', '2f0f7e46-c4ea-4c4d-9357-a6c26a976a87'];
  let isVertical = screenId && validScreenIds.includes(screenId);

  return Boolean(isVertical)
}


export default Slider;
