import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { CSSProperties } from "react";

import "swiper/css";
import { styled } from "@mui/system";

type ImageProps = {
  maxHeight?: CSSProperties["maxHeight"];
  height?: CSSProperties["height"];
};

const Image = styled("img")<ImageProps>(({ maxHeight, height = "100%" }) => ({
  maxHeight,
  width: "100%",
  height: height,
}));

const StyledSwiperSlide = styled(SwiperSlide)({
  display: "flex",
  justifyContent: "center",
});

export type AutoplaySliderProps = {
  imgs: string[];
} & ImageProps;

function AutoplaySlider({ imgs: imgsProp, maxHeight }: AutoplaySliderProps) {
  const imgs = imgsProp.map((img, i) => (
    <StyledSwiperSlide key={i}>
      <Image maxHeight={maxHeight} height={maxHeight} src={img} />
    </StyledSwiperSlide>
  ));

  return (
    <Swiper
      allowTouchMove={false}
      centeredSlides
      loop={true}
      autoplay={{
        delay: 10_000,
      }}
      modules={[Autoplay]}
    >
      {imgs}
    </Swiper>
  );
}

export default AutoplaySlider;
